import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Section from '../components/Section';
import defaultTheme from '../utils/themes';
import { Heading1, Heading2 } from '../components/Typography';
import HeroSection from '../components/HeroSection';
import Card from '../components/Card';
import Button, { IconWrapper } from '../components/Button';
import Media from '../components/Media';
import Grid from '../components/Grid';
import pdf2 from '../images/pdf2.svg';
import download from '../images/download.svg';
import complainsHandlingStatementPdf from '../files/Complaints_Handling_Statement_v1.2.pdf'
import businessContinuityPdf from '../files/Business-continuity-and-disaster-recovery-policy-v1.5 - 310325.pdf'
import environmentalPolicyPdf from '../files/Environmental-and-sustainability-policy-v1.5 310325.pdf'
import equalityPolicyPdf from '../files/Equality Diversity & Inclusion Policy Statement v1.1.pdf'
import jacobsPrivacyNoticePdf from '../files/Privacy Notice (Customer) - Jacobs Enforcement v4.1.pdf'
import healthAndSafetyPolicyPdf from '../files/Health-and-safety-policy-statement-of-intent-v1.6 - 310325.pdf'
import informationSecurityPolicyPdf from '../files/Information Security Policy Statement v1.1.pdf'
import qualityPolicyPdf from '../files/Quality-policy-v1.5-310325.pdf'
import safeguardingVulnerabilityPolicyPdf from '../files/Safeguarding-vulnerability-policy-v1.7-310325.pdf'
import Flexbox from '../components/Flexbox'
import SEO from '../components/seo'
import carbonReductionPlan from '../files/Carbon_Reduction_Plan-2024.pdf'
import antiMoneyLaundering from '../files/Anti-money-laundering-policy-v1.2 - 250324 - Reviewed 31.03.25.pdf'
import modernSlaveryPolicy from '../files/Modern-Slavery-&-Human-Trafficking-Policy-v1.0.pdf'
import dataProtectionPolicy from '../files/Data Protection Policy Statement v1.0.pdf'
import riskManagementPolicyStatement from '../files/Data Protection Policy Statement v1.0.pdf'

const CustomIconWrapper = styled(IconWrapper)`
  padding-left: 0px;
  padding-right: 8px;
  top: -2px;
`
const ButtonStyleOverride = css`
  :hover {
    background: none;
    color: #243169;
  }
`
const DownloadCard = ({ title, link }) => (
  <Card
    paddingTop='22px'
    paddingBottom='18px'
    paddingLeft='28px'
    paddingRight='28px'
    css={`
      h2 {
        font-size: 0.9rem;
      }
    `}
  >
    <Grid
      gridTemplateRows='1fr'
      gridTemplateColumns='auto 1fr'
      gridColumnGap='8px'
      css={`
        height: 100%;
      `}
    >
      <Media src={pdf2} marginLeft='0px' marginTop='0px' width='40px' />
      <Flexbox
        alignItems='flex-start'
        flexDirection='column'
        justifyContent='space-between'
      >
        <Heading2 lineHeight='1.1' marginBottom='0.6'>
          {title}
        </Heading2>
        <Button
          backgroundColor='transparent'
          fontSize='14px'
          fontWeight='700'
          color='primary'
          icon={
            <Media
              src={download}
              marginLeft='0px'
              marginRight='0px'
              marginTop='0px'
              marginBottom='0px'
            />
          }
          IconWrapperComponent={CustomIconWrapper}
          lineHeight='18px'
          onClick={useCallback(() => window.open(link, '_blank'), [link])}
          paddingLeft='0px'
          textTransform='none'
          css={ButtonStyleOverride}
        >
          Download
        </Button>
      </Flexbox>
    </Grid>
  </Card>
)

DownloadCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

const PolicyDownloadPage = () => (
  <>
    <SEO
      title='Policy Download Area'
      description={`
        Download our latest policies here.
        `}
    />
    <HeroSection paddingTop='8px'>
      <Heading1 marginTop='120px'>Policy Download Area</Heading1>

      {/* <Paragraph>
        Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec
        ullamcorper nulla non metus auctor fringilla. Sed posuere consectetur
        est at lobortis.
        </Paragraph> */}
    </HeroSection>

    <Section backgroundColor='grey' paddingTop='60px' paddingBottom='280px'>
      <Grid
        gridTemplateRows={useMemo(
          () => ({
            xs: 'repeat(9, auto)',
            md: 'repeat(5, 1fr)',
            lg: 'repeat(3, 1fr)',
          }),
          []
        )}
        gridTemplateColumns={useMemo(
          () => ({
            xs: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          }),
          []
        )}
        gridGap={{
          xs: '20px',
          md: '30px',
          lg: '40px',
        }}
      >
        <DownloadCard
          title='Complaints Handling Statement'
          link={complainsHandlingStatementPdf}
        />

        <DownloadCard
          title='Business Continuity & Recovery Policy'
          link={businessContinuityPdf}
        />

        <DownloadCard
          title='Environmental Policy'
          link={environmentalPolicyPdf}
        />

        <DownloadCard
          title='Equality and Diversity Policy'
          link={equalityPolicyPdf}
        />

        <DownloadCard
          title='Health and Safety Policy'
          link={healthAndSafetyPolicyPdf}
        />

        <DownloadCard
          title='Information Security Policy'
          link={informationSecurityPolicyPdf}
        />

        <DownloadCard
          title='Jacobs Privacy Notice'
          link={jacobsPrivacyNoticePdf}
        />

        <DownloadCard title='Quality Policy' link={qualityPolicyPdf} />

        <DownloadCard
          title='Safeguarding / Vulnerability Policy'
          link={safeguardingVulnerabilityPolicyPdf}
        />

        <DownloadCard
          title='Carbon Reduction Plan'
          link={carbonReductionPlan}
        />
        <DownloadCard
          title='Anti-Money Laundering Policy'
          link={antiMoneyLaundering}
        />
        <DownloadCard
          title='Modern Slavery & Human Trafficking Policy'
          link={modernSlaveryPolicy}
        />
        <DownloadCard
          title='Data Protection Policy Statement'
          link={dataProtectionPolicy}
        />
        <DownloadCard
          title='Risk Management Policy Statement'
          link={riskManagementPolicyStatement}
        />
      </Grid>
    </Section>
  </>
)

PolicyDownloadPage.propTypes = {
	theme: PropTypes.object,
};

PolicyDownloadPage.defaultProps = {
	theme: defaultTheme,
};

export default PolicyDownloadPage;
